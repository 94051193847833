import './App.css';
import headerImage from './assets/header.jpg'
import {joursAvantDate} from "./utils/utils";
import {ProgramEvent} from "./components/ProgramEvent";
import logoCadeau from "./assets/cadeau.svg"
import ImageSlider from "./components/ImageSlider";
import addressMairie from "./assets/mairieaddress.png"
import addressManoir from "./assets/manoiraddress.png"

function App() {
    const daysBefore = joursAvantDate("2024-07-13")
    console.log(daysBefore)
    return (
        <div>
            <section id="header">
                <div className={"w-full bg-blue-200 flex h-screen small-screen-height"}>
                    <div className={"relative w-full h-full flex justify-center"}>
                        <img src={headerImage} className="w-full object-cover" alt={"Nous"}/>
                        <p
                            className={"absolute bottom-1/4 font-lora text-4xl sm:text-7xl text-white"}>{`J - ${daysBefore}`}</p>
                    </div>
                    <p className={"font-lora absolute left-5 top-5 font-bold text-white"}>SONYA & FABIO</p>
                </div>
            </section>
            <section id="shesaidyes">
                <div className={"flex justify-center"}>
                    <div className={"max-h-screen w-full p-10 sm:px-36 text-center"}>
                        <div className={"flex-col p-5 w-full h-full bg-primary"}>
                            <p className={"font-lora font-bold text-2xl sm:text-4xl mb-7"}>SHE SAID YES !</p>
                            <p className={"font-lora text-xs sm:text-base mb-4"}>Nous avons l’immense joie de vous
                                convier à notre mariage le :</p>
                            <p className={"font-lora text-lg sm:text-xl mb-4"}>13 juillet 2024</p>
                            <p className={"font-lora text-xs sm:text-base mb-4"}>Nous sommes impatients de partager
                                cette journée unique avec vous, en espérant vous y voir nombreux.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="program">
                <div>
                    <div className={"flex justify-center"}>
                        <p className={"font-lora font-bold text-2xl sm:text-4xl mb-7"}>LE PROGRAMME</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-3 gap-8 px-10 md:px-20">
                        <ProgramEvent logo={"mairie"} title={"Cérémonie Civile"} hour={"14H - 15H"} address={["Place de la mairie", "77600 Bussy-Saint-Georges"]}/>
                        <ProgramEvent logo={"ceremony"} title={"Cérémonie Laïque"} hour={"17H30 - 18H30"} address={["Le Yacht Club, Manoir des Cygnes", "150 Les Berges de Seine,", "91830 Le Coudray-Montceaux"]}/>
                        <ProgramEvent logo={"wine"} title={"Vin d’honneur"} hour={"18H30 - 20H"} address={["Le Yacht Club, Manoir des Cygnes", "150 Les Berges de Seine,", "91830 Le Coudray-Montceaux"]}/>
                        <ProgramEvent logo={"diner"} title={"Dîner"} hour={"20H"} address={["Le Yacht Club, Manoir des Cygnes", "150 Les Berges de Seine,", "91830 Le Coudray-Montceaux"]}/>
                        <ProgramEvent logo={"dance"} title={"Ouverture de Bal"} hour={""} address={["Le Yacht Club, Manoir des Cygnes", "150 Les Berges de Seine,", "91830 Le Coudray-Montceaux"]}/>
                    </div>
                </div>
            </section>
            <section id="rsvp">
                <div className={"flex justify-center"}>
                    <div className={"max-h-screen w-full p-10 sm:px-36 text-center"}>
                        <div className={"flex-col p-5 w-full h-full bg-primary"}>
                            <p className={"font-lora font-bold text-2xl sm:text-4xl my-6"}>RSVP</p>
                            <p className={"font-lora text-xs sm:text-base mb-4"}>Merci de répondre au questionnaire ci-dessous concernant le menu du mariage</p>
                            <p style={{color:"#2960ED"}} className={"font-lora underline text-xs sm:text-base mb-4"}><a href={"https://forms.gle/JEwq3F2gs4uReyh26"}>CLIQUEZ ICI POUR ACCEDER AU QUESTIONNAIRE</a></p>
                            <p className={"font-lora text-xs sm:text-base mb-4"}>Réponses souhaitées avant le 1er juin</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="cadeaux">
                <div className={"mt-4"}>
                    <div className={"flex justify-center"}>
                        <p className={"font-lora font-bold text-2xl sm:text-4xl mb-7"}>CADEAU DE MARIAGE</p>
                    </div>
                    <div className={"flex justify-center"}>
                        <div className={"w-2/3 sm:1/3"}>
                            <div className={"flex justify-center mb-4"}>
                                <div style={{borderRadius: "50%"}} className={"flex items-center justify-center bg-primary h-40 w-40"}>
                                    <img src={logoCadeau} className={"p-2"} alt={"cadeau"}/>
                                </div>
                            </div>

                            <p className={"font-lora text-xs text-center sm:text-base mb-4"}>Pour notre mariage nous avons prévu de partir en Lune de Miel le mois suivant l’évènement.</p>
                            <p className={"font-lora text-xs text-center sm:text-base mb-4"}>Nous avons également pour projet de déménager prochainement.</p>
                            <p className={"font-lora text-xs text-center sm:text-base mb-4"}>Si vous souhaitez contribuer à nos projets, nous avons créer une cagnotte en ligne (lien ci-dessous). Une urne sera également prévue sur place le jour du mariage.</p>
                            <p style={{color:"#2960ED"}} className={"font-lora underline text-xs text-center sm:text-base mb-4"}><a href={"https://www.leetchi.com/fr/c/mariage-sonya-fabio-13072024-1687044"}>LIEN VERS LA CAGNOTTE</a></p>
                            <p className={"font-lora text-xs text-center sm:text-base mb-8"}>Merci à tous !</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="slider">
                <ImageSlider/>
            </section>
            <section id={"maps"}>
                <div className={"flex items-center flex-col mt-8"}>
                    <p className={"font-lora font-bold text-2xl sm:text-4xl mb-7"}>LOCALISATION</p>
                    {
                        [{image: addressMairie, address: "Place de la Mairie, 77600 Bussy-Saint-Georges", infoTransport: "RER A (Bussy-Saint-Georges)", infoVoiture: "Autoroute A4, sortie Bussy-Saint-Georges" },
                            {image: addressManoir, address: "150 Les Berges de Seine, 91830 Le Coudray-Montceaux", infoTransport: "RER D (Bras de Fer-Evry Genopole) puis Bus 301 ou 402", infoVoiture: "Prendre l'Autoroute A6, sortie 11 Coudray Montceaux, se diriger vers le centre de Coudray-Montceaux, puis suivre le fléchage urbain 'Manoir des Cygnes'"}].map((item, index) =>
                            <div className={"w-full sm:w-1/2 mb-2"}>
                                <img src={item.image} alt={"address"}/>
                                <div className={"font-lora text-xs sm:text-base mb-4 mt-2.5 text-center mx-5"}>{item.address}</div>
                                <div className={"font-lora text-xs sm:text-base mb-4 mt-2.5 text-center mx-5"}><strong>En transport : </strong> {item.infoTransport}</div>
                                <div className={"font-lora text-xs sm:text-base mb-4 mt-2.5 text-center mx-5"}><strong>En voiture : </strong>{item.infoVoiture}</div>
                            </div>
                        )
                    }

                </div>
            </section>
            <section id={"info pratiques"}>
                <div className={"flex justify-center"}>
                    <div className={"w-full p-10 sm:px-36 text-center"}>
                        <div className={"flex-col p-5 w-full h-full bg-primary font-lora"}>
                            <p className={"font-bold text-2xl sm:text-4xl my-6"}>INFORMATIONS PRATIQUES</p>
                            <ul className={"list-disc text-left space-y-[10px] pl-5 text-xs sm:text-base mb-4"}>
                                <li className={"pl-2"}>La cérémonie de mariage au sein de la Mairie de Bussy-Saint-Georges, aura lieu à 14h. Attention il faudra arriver avec au moins 20 minutes d'avance.</li>
                                <li className={"pl-2"}>La cérémonie Laïque aura lieu à 17h30 dans le jardin du Yacht Club au Manoir des Cygnes. Un parking privé est réservé aux invités sur place. Il faudra bien faire attention, il s’agit du 2ème Parking, situé après le panneau du "Manoir des Cygnes". Merci de prévoir votre arrivée avec 30 min d’avance.</li>
                                <li className={"pl-2"}>Un service de baby-sitting est prévu pour les enfants de 3 à 12 ans, afin de les garder et les faire diner. Ils auront un espace dédié dans la salle du diner.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"contacts"}>
                <div className={"flex justify-center font-lora"}>
                    <div className={"text-center w-full p-10 sm:px-36"}>
                        <p className={"font-bold text-2xl sm:text-4xl my-6"}>CONTACT EN CAS DE BESOIN</p>
                        <p className={"text-xs sm:text-base mb-4 "}>Voici les personnes à contacter le jour J en cas de besoin :</p>
                        <ul className={"space-y-[10px] pl-5 text-xs sm:text-base mb-4"}>
                          <li>Nahema GARBA : 06 12 60 20 45</li>
                          <li>Sarah GARBA : 07 61 78 81 62</li>
                          <li>Evelyne ICARDO : 06 65 57 69 17</li>
                          <li>Monique GARBA : 06 41 18 39 36</li>
                          <li>Léa NOIROT : 06 75 15 33 54</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id={"footer"}>
                <div style={{height: "30vh"}} className={"relative bg-black mt-5"}>
                    <div className={"flex flex-col justify-center items-center h-full"}>
                        <div className={"font-lora text-white text-lg sm:text-xl mb-1"}>SONYA & FABIO</div>
                        <div className={"font-lora text-white text-base sm:text-lg mb-1"}>13 Juillet 2024</div>
                    </div>
                    <div className={"absolute bottom-4 left-4"}>
                        <div className={"font-lora text-white text-xs sm:text-base mb-1"}>Contact : mariagefabioetsonya@gmail.com</div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default App;
