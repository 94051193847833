import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../assets/image1.jpg"
import image2 from "../../assets/image2.jpg"
import image3 from "../../assets/image3.jpg"
import image4 from "../../assets/image4.jpg"
import image5 from "../../assets/image5.jpg"
import image6 from "../../assets/image6.jpg"
import image7 from "../../assets/image7.jpg"

export const ImageSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        swipe: false, // Désactiver le défilement tactile
        draggable: false,
    };

    return (
        <Slider {...settings}>
            {
                [image1, image5, image3, image6, image2, image4, image7].map((item, index) =>
                    <div key={index} className={"mr-10"}>
                        <img src={item} className="slider-image" alt="Nous"/>
                    </div>
                )
            }
        </Slider>
    );
};

export default ImageSlider;
