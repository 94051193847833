import logoMairie from "../../assets/mairie.svg";
import logoDiner from "../../assets/diner.svg"
import logoCeremony from "../../assets/ceremony.svg"
import logoDanse from "../../assets/danse.svg"
import logoWine from "../../assets/wine.svg"



const logoMapping = {
    "mairie" : logoMairie,
    "diner": logoDiner,
    "ceremony": logoCeremony,
    "dance": logoDanse,
    "wine": logoWine
}


export const ProgramEvent = ({logo, hour, title, address}) => {
    return(
        <div className={"flex flex-row, gap-7"}>
            <div>
                <div className={"bg-primary md:h-28 md:w-28 h-20 w-20 flex p-3 rounded-md"}>
                    <img src={logoMapping[logo]} alt={logo}/>
                </div>
                <p className={"text-center font-lora text-xs sm:text-base mt-2"}>{hour}</p>
            </div>


            <div className={"mt-2"}>
                <p className={"font-lora font-bold mb-2"}>{title}</p>
                {
                    address.map((item, index) =>
                        <p key={index} className={"font-lora text-xs sm:text-base"}>{item}</p>
                    )
                }
            </div>

        </div>
    )
}